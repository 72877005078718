import { PropsWithChildren } from "react";
import OnceInView from "./once.in-view";

type ImpressionProps = {
  impression?: string;
};

const Impression = (props: PropsWithChildren<ImpressionProps>) => {
  if (!props.impression) {
    return <>{props.children}</>;
  }

  return (
    <OnceInView
      callback={() => {
        const pixel = document.createElement("div");

        pixel.innerHTML = props.impression?.replace(
          "[timestamp]",
          String(Date.now())
        )!;

        document.body.appendChild(pixel);
      }}
    >
      {props.children}
    </OnceInView>
  );
};

export default Impression;
