"use client";

import { useEffect, useRef } from "react";

type OnceInViewProps = {
  children: React.ReactNode;
  callback: () => void;
};

const OnceInView = (props: OnceInViewProps) => {
  const elementRef = useRef<HTMLDivElement | null>(null);
  const hasTriggered = useRef(false);

  useEffect(() => {
    const element = elementRef.current;

    const observerCallback: IntersectionObserverCallback = (
      entries,
      observer
    ) => {
      const [entry] = entries;
      if (entry.isIntersecting && !hasTriggered.current) {
        props.callback();

        hasTriggered.current = true;
        observer.disconnect();
      }
    };

    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.1,
    });

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.disconnect();
      }
    };
  }, [props.callback]);

  return <div ref={elementRef}>{props.children}</div>;
};

export default OnceInView;
